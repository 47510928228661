import classnames from "classnames";
import React, { ReactNode } from "react";
import Card from "../../components/Card/Card";
import styles from "./IconCard.module.scss";

interface IconCardProps {
  icon: ReactNode;
  children: ReactNode;
  className?: string;
}

const IconCard = ({ className, icon, children }: IconCardProps) => (
  <Card className={classnames(styles.root, className)}>
    {icon}
    <div className={styles.text}>{children}</div>
  </Card>
);

export default IconCard;
