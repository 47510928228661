import React from "react";
import styles from "./MicrobitEmoji.module.scss";
import classnames from "classnames";
import { BrandColorSubset } from "../../model/colors";

export enum EmojiType {
  TICKS = "ticks",
  HEARTS = "hearts",
  NORMAL = "normal"
}

export type EmojiColor = BrandColorSubset<
  | "deepPink"
  | "dodgerBlue"
  | "iris"
  | "pastelRed"
  | "seafoamBlue"
  | "vibrantGreen"
>;

const infiniteArrayGenerator = function<T>(array: T[]) {
  return function*() {
    let i = 0;
    while (true) {
      yield array[i % array.length];
      i = i + 1;
    }
  };
};

const colorSequence = infiniteArrayGenerator<EmojiColor>([
  "vibrantGreen",
  "dodgerBlue",
  "deepPink",
  "pastelRed",
  "iris",
  "seafoamBlue"
]);

const typeSequence = infiniteArrayGenerator([
  EmojiType.TICKS,
  EmojiType.HEARTS,
  EmojiType.NORMAL
]);

export function* emojiSequence() {
  const colors = colorSequence();
  const types = typeSequence();
  while (true) {
    yield {
      color: colors.next().value,
      type: types.next().value
    };
  }
}

export interface MicrobitEmojiProps {
  type: EmojiType;
  color: EmojiColor;
}

const MicrobitEmoji = ({ type, color }: MicrobitEmojiProps) => (
  <svg width="98" height="98" viewBox="0 0 98 98">
    <g fill="none" fillRule="evenodd">
      <rect
        width="96.5"
        height="96.5"
        x=".75"
        y=".75"
        stroke="#BFBFBF"
        strokeWidth="1.5"
        rx="48.25"
      />
      <path
        className={classnames(styles.emoji, styles[color])}
        fillRule="nonzero"
        d={emojiTypeToPath[type]}
      />
    </g>
  </svg>
);

const emojiTypeToPath: Record<EmojiType, string> = {
  [EmojiType.TICKS]:
    "M45.605 63c.278 1.496 1.69 2.64 3.397 2.64 1.704 0 3.116-1.144 3.395-2.64H55c-.272 2.884-2.76 5-5.998 5-3.242 0-5.729-2.116-6.002-5zm14.51-34C68.04 29.062 74 35.225 74 43.388c0 8.171-6.018 14.332-13.997 14.332H37.671C29.855 57.547 24 51.448 24 43.388c0-8.162 5.957-14.322 13.876-14.384V29h22.24zm-.118 5.942H38.005c-4.422 0-8.005 3.769-8.005 8.418 0 4.601 3.512 8.338 7.87 8.413l22.127.005c4.419 0 8.003-3.769 8.003-8.418 0-4.65-3.584-8.418-8.003-8.418zM45.11 40.391c.484.485.516 1.252.097 1.775l-.097.108-5.35 5.336a1.317 1.317 0 0 1-1.01.388 1.25 1.25 0 0 1-.918-.343l-.01-.012-.01-.012-2.344-2.189-.027-.02a1.443 1.443 0 0 1-.117-1.952 1.252 1.252 0 0 1 1.737-.213l.105.088 1.607 1.502 4.443-4.431a.19.19 0 0 0 .011-.013l.01-.013a1.318 1.318 0 0 1 1.873 0zm19 0c.484.485.516 1.252.097 1.775l-.097.108-5.35 5.336a1.317 1.317 0 0 1-1.01.388 1.252 1.252 0 0 1-.918-.343l-.01-.012-.01-.012-2.344-2.189c-.01-.007-.018-.012-.027-.02a1.442 1.442 0 0 1-.116-1.952 1.252 1.252 0 0 1 1.737-.213l.104.088 1.607 1.501 4.443-4.43a.154.154 0 0 0 .011-.013l.01-.013a1.319 1.319 0 0 1 1.873 0z",
  [EmojiType.HEARTS]:
    "M51.95 63c.744 0 1.347.585 1.347 1.306 0 .395-.181.75-.468.989l.01.01-2.744 2.55-.005-.005-.098.086a1.38 1.38 0 0 1-1.786-.085l-.004.003-2.744-2.549.01-.01a1.286 1.286 0 0 1-.468-.99c0-.72.603-1.305 1.347-1.305.43 0 .811.194 1.058.497l1.743 1.62 1.744-1.62c.22-.27.548-.454.92-.49L51.95 63zm8.354-34c7.964.062 13.956 6.225 13.956 14.388 0 8.172-6.049 14.332-14.07 14.332H37.743C29.885 57.547 24 51.448 24 43.388c0-8.162 5.988-14.322 13.948-14.385V29h22.356zm-.113 6.125H38.068c-4.448 0-8.052 3.687-8.052 8.235 0 4.502 3.533 8.158 7.917 8.232l22.258.004c4.445 0 8.05-3.687 8.05-8.236 0-4.548-3.605-8.235-8.05-8.235zM39.46 40c.952 0 1.69.803 1.69 1.838 0 1.27-1.045 2.308-2.644 3.845l-.43.467-.432-.467C36.045 44.146 35 43.109 35 41.838c0-1.035.739-1.838 1.69-1.838.525 0 1.047.268 1.385.702.338-.434.86-.702 1.385-.702zm22 0c.952 0 1.69.803 1.69 1.838 0 1.27-1.045 2.308-2.644 3.845l-.43.467-.432-.467C58.045 44.146 57 43.109 57 41.838c0-1.035.739-1.838 1.69-1.838.525 0 1.047.268 1.385.702.338-.434.86-.702 1.385-.702z",
  [EmojiType.NORMAL]:
    "M46.268 63.01a3.245 3.245 0 0 0 3.193 2.706 3.247 3.247 0 0 0 3.191-2.706H55.1c-.256 2.958-2.594 5.128-5.64 5.128-3.047 0-5.385-2.17-5.642-5.128zM60.3 29c7.964.062 13.955 6.224 13.955 14.386 0 8.171-6.048 14.332-14.068 14.332H37.74C29.885 57.545 24 51.446 24 43.386c0-8.16 5.987-14.32 13.947-14.382V29h22.354zm-.113 6.124h-22.12c-4.448 0-8.052 3.687-8.052 8.235 0 4.502 3.532 8.157 7.916 8.231l22.256.005c4.445 0 8.05-3.687 8.05-8.236 0-4.548-3.605-8.235-8.05-8.235zm-22.251 4.757a3.077 3.077 0 1 1-.004 6.154 3.077 3.077 0 0 1 .004-6.154zm22.701-.173a3.077 3.077 0 1 1 0 6.154 3.077 3.077 0 0 1 0-6.154z"
};

export default MicrobitEmoji;
