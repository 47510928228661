import React from "react";
import { Col, Row, Wrapper } from "../../components/Grid";
import styles from "./AwardsPageLayout.module.scss";
import { urlForDocument } from "../../urls";
import HorizontalFullWidthImageCard from "../../components/HorizontalFullWidthImageCard/HorizontalFullWidthImageCard";
import { SanityAward } from "../../model/impact";
import IconCard from "../../components/IconCard/IconCard";
import { textFromFirstBlockNoFallback } from "../../utils/blocks";
import MicrobitEmoji, {
  emojiSequence,
  MicrobitEmojiProps
} from "../../components/MicrobitEmoji/MicrobitEmoji";
import CardContent from "../../components/CardContent/CardContent";
import Text from "../../components/Text/Text";

interface AwardsPageLayoutProps {
  title: string;
  introductionText: string;
  awards: SanityAward[];
}

const AwardsPageLayout = ({
  title,
  awards,
  introductionText
}: AwardsPageLayoutProps) => {
  const [topAward, ...otherAwards] = awards;
  const emojis = emojiSequence();
  return (
    <Wrapper>
      <Row className={styles.titleSection}>
        <Col xs={12} md={5} sm={8} offsetSm={1}>
          <Text variant="h1">{title}</Text>
          <Text variant="defaultLight">{introductionText}</Text>
        </Col>
      </Row>
      {topAward && (
        <Row justify="center">
          <Col sm={10}>
            <HorizontalFullWidthImageCard image={topAward.image}>
              <CardContent
                title={topAward.title}
                titleVariant="h2"
                text={awardFirstParagraph(topAward)}
                textMaxLines={-1}
                href={urlForDocument(topAward)}
                readMore
              />
            </HorizontalFullWidthImageCard>
          </Col>
        </Row>
      )}
      <Row justify="center" className={styles.otherCardsWrapper}>
        <Col sm={10}>
          <Row className={styles.otherCards}>
            {otherAwards.map(award => (
              <Col md={6}>
                <IconCard
                  className={styles.iconCard}
                  icon={
                    <MicrobitEmoji
                      {...(emojis.next().value as MicrobitEmojiProps)}
                    />
                  }
                >
                  <CardContent
                    title={award.title}
                    titleVariant="h3"
                    href={urlForDocument(award)}
                    readMore
                  />
                </IconCard>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

const awardFirstParagraph = (award: SanityAward) =>
  textFromFirstBlockNoFallback(award._rawContent) || "";

export default AwardsPageLayout;
