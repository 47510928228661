import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import AwardsPageLayout from "../layouts/AwardsPageLayout/AwardsPageLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext } from "../model/common";
import { SanityAward, SanityAwardsPage } from "../model/impact";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query AwardsPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityAwardsPage(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      metaDescription
      introductionText
    }
    awards: allSanityAward(sort: { fields: date, order: DESC }) {
      nodes {
        _id
        _type
        slug {
          current
        }
        _rawContent
        date
        title
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const AwardsPage = ({
  pageContext,
  data: { menus, page, awards },
  location
}: AwardsPageProps) => {
  const { title } = page;
  return (
    <PageLayout
      siteArea={SiteArea.IMPACT}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates,
        type: "article"
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <AwardsPageLayout
        title={title}
        introductionText={page.introductionText}
        awards={awards.nodes}
      />
    </PageLayout>
  );
};

export default AwardsPage;

interface AwardsPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityAwardsPage;
    awards: { nodes: SanityAward[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
