import React from "react";
import styles from "./HorizontalFullWidthImageCard.module.scss";
import { SanityImage } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import classnames from "classnames";
import Card from "../../components/Card/Card";

interface HorizontalFullWidthImageCardProps {
  image: SanityImage;
  children: JSX.Element;
}

const HorizontalFullWidthImageCard = ({
  image,
  children
}: HorizontalFullWidthImageCardProps) => (
  <Card className={classnames(styles.root, styles.card)} type="main">
    <img
      alt=""
      className={styles.image}
      src={
        imageUrlBuilder
          .image(image)
          .fit("fill")
          .height(370)
          .width(648)
          .bg("fff")
          .url()!
      }
    />
    <div className={styles.content}>{children}</div>
  </Card>
);

export default HorizontalFullWidthImageCard;
